"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
require("@gibme/web-ui");
const check_casting = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield $.fetch('http://cast.vcast.mobi:8088', {
            timeout: 1500
        });
        if (response.ok) {
            const json = yield response.json();
            return {
                registered: json.registered,
                hotelname: json.location.name
            };
        }
    }
    catch (_a) { }
});
const register_casting = (lastname, room) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield $.fetch('http://cast.vcast.mobi:8088', {
            timeout: 2000,
            method: 'POST',
            json: {
                lastname,
                room
            }
        });
        return response.ok;
    }
    catch (_a) {
        return false;
    }
});
$(document).ready(() => __awaiter(void 0, void 0, void 0, function* () {
    $('#cast_icon').on('click', () => __awaiter(void 0, void 0, void 0, function* () {
        const lastname = $('#lastname').val();
        const room = parseInt($('#room').val()) || 0;
        if (room === 0) {
            return $.statusModal().show({
                title: 'Error',
                titleClass: 'text-danger',
                body: 'Please check your room number and try again'
            });
        }
        if (yield register_casting(lastname, room)) {
            $('#signin').addClass('d-none');
            $('#ready').removeClass('d-none');
        }
        else {
            return $.statusModal().show({
                title: 'Error',
                titleClass: 'text-danger',
                body: 'Could not register your session. Please try again.'
            });
        }
    }));
    const check = yield check_casting();
    $('#checking').addClass('d-none');
    if (check) {
        $('#success').removeClass('d-none');
        if (check.registered) {
            $('#ready').removeClass('d-none');
        }
        else {
            $('#signin').removeClass('d-none');
        }
    }
    else {
        $('#failure').removeClass('d-none');
    }
}));
